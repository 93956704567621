.user-lists {
  .dropdown-menu {
    .dropdown-item {
      display: flex;
      gap: 16px;
    }
  }

  .pdf-reports-dropdown-menu {
    min-width: 500px;
  }
}