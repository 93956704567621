.filters-panel-container {
  position: relative;

  .open-rlm {
    right: -1px;
  }

  .close-rlm {
    right: -45%; // 50% prev
  }

  .report-list-menu {
    position: fixed;
    width: 45%; // 50% prev
    top: 72px;
    z-index: 100;
    transition: .1s all linear;
    max-height: 72%;
    overflow-y: scroll;

    .card-header {
      background-color: $black;
      border-bottom: 1px solid #D0D0CE;
      min-height: 35px;
    }

    .filters-panel-section {
      margin-bottom: 1.6em;
      padding-bottom: 1.6em;
      border-bottom: solid 1px #D0D0CE;

      h5 {
        min-height: 26px;
      }

      .filters-panel-section-item {
        margin-top: 2px;
        &:nth-of-type(odd) {
          padding-left: 60px;
        }

        &:nth-of-type(even) {
          padding-right: 60px;
        }

        label {
          color: $dk-dark-gray;
          font-size: 12px;
        }

        .input-container-text,
        .input-container-date {
          position: relative;
        }

        .input-container-text {

          input {
            padding-left: 30px;
          }

          &:after {
            content: "";
            background-image: url("../../images/icons/search_icon.png");
            background-repeat: no-repeat;
            background-size: 16px 16px;
            background-position: center;
            font-size: 16px;
            color: $dk-dark-gray;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        input {
          position: relative;
          font-size: 14px;
        }

        .input-container-date {
          &:after {
            content: "";
            background-image: url("../../images/icons/calendar_icon.png");
            background-repeat: no-repeat;
            background-size: 16px 16px;
            background-position: center;
            font-size: 16px;
            color: $dk-dark-gray;
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
}
