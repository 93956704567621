@import "fonts";
@import "vendor/vendor-main";
@import "~react-datepicker/dist/react-datepicker.css";
@import '~react-toastify/dist/ReactToastify.css';
@import '~react-vis/dist/style.css';
@import "react-toggle/style.css";
@import "~react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
@import '~react-sliding-side-panel/src/index.css';

// Pages
@import "pages/Login";
@import "pages/ProjectList";
@import "pages/ReportsList";
@import "pages/Summary";
@import "pages/ManageCookies";
@import "pages/UserLists";

// Parts
@import "parts/Sidebar";
@import "parts/Topbar";
@import "parts/React-datepicker";
@import "parts/React-select";
@import "parts/Toastify";
@import "parts/React-data-table-component";
@import "parts/Image";
@import "parts/Pagination";
@import "parts/Label";
@import "parts/Spinner";
@import "parts/FilterMenu";
@import "parts/ActionsMenu";
@import "parts/Panel";
@import "parts/Subheader";
@import "parts/DkModal";
@import "parts/DraftJS";

.text-black {
  color: $black !important;
}

// Globals
html, body, #root {
  height: 100%;
}

.bg-black {
  background-color: $black;
}

#content {
  padding-bottom: 30px;
}

.sidebar-divider {
  background-color: $dk-deloitte-green;
}

.btn-dark-green {
  background-color: $dk-deloitte-dark-green;
  color: $white;

  &:hover {
    color: white;
  }
}

.close-icon {
  width: 14px;
  height: 14px;
  background: url("../images/icons/close_icon.png") no-repeat;

  &:hover {
    cursor: pointer;
  }
}

.white-close-icon {
  width: 14px;
  height: 14px;
  background: url("../images/icons/white_close_icon.png") no-repeat;

  &:hover {
    cursor: pointer;
  }
}

.no-subheader {
  margin-top: 30px;
}

.is-pointer {
  cursor: pointer;
}

//#root > div {
//  height: 100%;
//}