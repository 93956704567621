.user-lists-table,
.pdf-reports-table {
  border: 1px solid $dk-deloitte-green;
  header {
    padding: 0;
  }

  .actions-container {

    .pdf {color: #4e73df}
    .delete {color: red;}

    &:hover {
      cursor: pointer;
    }
  }

  .rdt_Pagination {
    border-top: 1px solid $dk-deloitte-green;
  }

  .rdt_TableHeadRow {
    border-bottom: 1px solid $dk-deloitte-green;
  }

  .rdt_Table {
    min-height: 75vh;
  }
}