.sidebar {

  .sidebar-brand {
    margin-left: -24px;
  }

  .logo {
    width: 185px;
    height: auto;
  }

  .sidebar-heading {
    font-weight: normal;
    font-size: 12px;
  }

  .active {
    i, span {
      color: $dk-deloitte-green;
    }
  }

  .nav-link {
    color : white;
    font-weight: 400;

    span {
      &:hover {
        font-weight: 700;
        color: $white;
      }
    }
  }
}