.panel {
    background-color: #fff;

.panel-content {
    display: flex;
    flex-direction: column;
p {
    margin-bottom: 0;
}
.img-container {
    background-color: black;
    padding: 15px 0;
img {
    max-height: 500px;
}
}
.panel-header {
    display: flex;
    justify-content: right;
    align-items: center;
    padding: 5px 20px 5px 15px;

    min-height: 44px;
    background-color: black;
    color: white;

    // STICKY HEADER
    position: sticky; top: 0;
    z-index: 1;

    .close-icon {
        color: red;
        background-color: white;
    }
.title {
    font-size: 14px;
    font-weight:bold;
}
}
.panel-body {
    flex: 1;
    padding: 15px 30px 15px 30px;
}
.panel-footer {
    background-color: #cdcdcd;
    min-height: 20px;
}
}
}
