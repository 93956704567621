@font-face {
  font-family: 'Muli';
  src: url('../fonts/muli/Muli.ttf') format('opentype');
}

@font-face {
  font-family: 'Muli Bold';
  src: url('../fonts/muli/Muli-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Muli BoldItalic';
  src: url('../fonts/muli/Muli-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Muli ExtraLight';
  src: url('../fonts/muli/Muli-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Muli ExtraLightItalic';
  src: url('../fonts/muli/Muli-ExtraLightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Muli Italic';
  src: url('../fonts/muli/Muli-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Muli Light';
  src: url('../fonts/muli/Muli-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Muli LightItalic';
  src: url('../fonts/muli/Muli-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Muli SemiBold';
  src: url('../fonts/muli/Muli-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Muli Semi-BoldItalic';
  src: url('../fonts/muli/Muli-Semi-BoldItalic.ttf') format('truetype');
}
