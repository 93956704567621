.svs {
  .svs-card {
    .svs-header-icon {
      font-size: 20px;
      padding: 0 10px;
      margin-right: 5px;
      &:hover {
        cursor: pointer;
      }
      &:first-child {
        padding-left: 0;
      }
    }

    .card-header {
      background-color: $black;
      span {
        font-size: 12px;
      }
      //border: 1px solid $dk-deloitte-green
    }

    .image-row {
      border: 1px solid $dk-delloite-light-gray;
      margin-bottom: 40px;
    }

    .svs-product-image {
      height: 300px;
      width: auto;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    .summary-col {
      padding-bottom: 15px;
      .image-col {
        div {
          min-height: 400px;
        }
      }

      .label-col {
        background-color: $dk-delloite-light-gray;

        p {
          font-size: 14px;
        }
      }

      .value-col {
        //background-color: #efefee;
      }
    }

    .image-col {
      div {
        min-height: 400px;
      }
    }

    .sections-col {
      margin-bottom: 15px;
      padding-bottom: 15px;

      &:last-child {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }

    .section-title-col {
      background-color: $dk-deloitte-green;
      min-height: 40px;
      display: flex;
      align-items: center;
      h4 {
        font-size: 16px;
      }
    }

    .label-col,
    .value-col {
      box-shadow: inset 0px -1px 0px #D0D0CE;
    }

    .value-col {
      display: flex;
      align-items: center;
      overflow-wrap: anywhere;
      font-size: 14px;
      a {
        color: blue;
      }
    }

    .label-col {
      display: flex;
      align-items: center;
      background-color: $dk-delloite-light-gray;
      p {
        color: #343434;
      }
    }

    p {
      margin: 5px 0;
      font-size: 14px;
    }

    //.svs-features {
    //  .feature-category {
    //    background-color: #252324;
    //    color: white;
    //    font-weight:bold;
    //    text-align: center;
    //    border-left: 1px solid #7d7d7c;
    //    border-right: 1px solid #7d7d7c;
    //    border-top: 1px solid #7d7d7c;
    //    padding: 10px 0;
    //  }
    //
    //  .feature-col {
    //    background-color: #efefee;
    //    border: 1px solid #7d7d7c;
    //    text-align: center;
    //    padding: 10px 0;
    //  }
    //}
  }
}