.Toastify__toast {
  background-color: $black !important;
  text-align: center;
  color: $dk-deloitte-green;
  border: 1px solid $dk-deloitte-green;
}

.Toastify__progress-bar--default {
  background: $dk-deloitte-green !important;
}

.Toastify__close-button--default {
  color: $dk-deloitte-green
}
