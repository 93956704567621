.sticky-container > div {
  position: relative;
  z-index: 10;
}

.sticky {
  z-index: 100;
}

.subheader {
  min-height: 86px;
  background-color: $white;
  border-bottom: 1px solid $dk-deloitte-green;
  padding: 15px 30px;
  margin-bottom: 15px;
  z-index: 1000;
}


