.labels-list {
  .label-text {
    font-size: 80%;
    padding: 3px 10px 3px 10px;
    white-space: nowrap;
  }

  .label-remove {
    font-size: 80%;
    padding: 3px 10px 3px 10px;
    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }
}

//.img-card {
//  .label-text {
//    margin-left: 19px;
//  }
//}
