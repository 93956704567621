.img-card {
  position: relative;

  .img-id {
    font-size: 14px;
    font-weight: 600;
  }

  .list-group {
    border-top: none;
  }

  .img-selected-checkbox {
    position: absolute;
    top: 24px;
    left: 24px;
    width: 16px;
    height: 16px;
    accent-color: $black;
    border-radius: 0 !important;
  }

  .image-info-btn {
    color: white;
    background-color: $dk-deloitte-dark-green;
    font-size: 12px;
  }

  .scrape-badge {
    position: absolute;
    //z-index: 3;
    left: 10px;
    top: 10px;
  }

  .img-provider-logo {
    margin-right: 10px;
  }

  .image-wrapper {
    position: relative;
    //z-index: 1;
    height: 250px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    .image-overlay {
      position: absolute;
      //z-index: 2;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0.5);
    }

    .image-analyze-icon {
      position: absolute;
      //z-index: 3;
      top: 42%;
      left: 42%;
      transform: translate(-50%, -50%);
      font-size: 40px;
      color: #1cc88a;
    }
  }

  .image-label,
  .image-score {
    font-size: 12px;
    margin-bottom: 0px;
  }

  .info-box {
    z-index: 10;
    position: absolute;
    top:-30px;
    right: -350px;
    min-height: 700px;
    min-width: 400px;
    background-color: white;
    border: 1px solid black;
  }
}

.image-full {
  max-height: 400px;
  width: auto;
  max-width: 100%;
}

//.selected {
//  border-color: #1cc88a;
//  transition: .1s all linear;
//  transform: scale(0.90);
//  //box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
//  opacity: 0.5;
//}
