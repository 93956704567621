.rdw-editor-wrapper {
  width: 100%;

  .rdw-editor-toolbar {
    margin-bottom: 0;
  }

  .rdw-dropdown-selectedtext {
    span {
      color: black;
    }
  }

  .rdw-editor-main {
    padding: 0 0 0 15px;
  }
}

div.DraftEditor-root {
  //border: 1px solid #000;
  height: 200px;
  width: 100%;
  overflow-y: auto;
}

div.DraftEditor-editorContainer,
div.public-DraftEditor-content {
  //height: 100%;
}
