.pagination-items-count-select {
  max-width: 110px;
  min-width: 100px;
  margin-left: -15px;
  margin-bottom: 0;
}

.pagination {
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  flex-grow: 1;
  margin-left: 110px;

  .active {
    .page-link {
      background-color: $dk-deloitte-dark-green !important;
      border: none;
      color: white;
    }
  }

  .page-link {
    border: none;
    background: none;
    color: black;
  }

  .disabled {
    .page-link {
      border: none;
      background: none;
    }
  }
}

.pagination-row {
  padding-right: 15px;
}
