.manage-cookies {
  .always-active-badge {
    position: relative;
    background-color: #bbbbbb;
    padding: 2px 10px;
    text-transform: uppercase;
    font-weight: bold;
    margin-left: 20px;

    &:before {
      content: "";
      position: absolute;
      background-color: #bbbbbb;
      height: 100%;
      width: 20px;
      top: 0;
      left: -20px;
      border-radius: 50% 0 0 50%;
    }

    &:after {
      content: "";
      position: absolute;
      background-color: #bbbbbb;
      height: 100%;
      width: 20px;
      top: 0;
      right: -20px;
      border-radius: 0 50% 50% 0;
    }
  }

  .toggle-and-text-container {
    display: flex;

    .text-container {
      margin-left: 15px;
    }
  }

  .react-toggle {
    margin-top: 3px;
  }

  .cookie-nav {
    background-color: $black;
    min-height: 30px;
    border-bottom: 2px solid #97999B;
    padding: 15px 0;
  }

  .cookie-intro {
    background-color: $black;
    padding: 48px 0;
  }

  .cookies-table {
    th {
      vertical-align: middle;
    }
    td {
      text-align: right;
      width: 50%;
    }
  }
}