.actions-panel-container {
  position: relative;

  .open-rlm {
    right: -15px;
  }

  .close-rlm {
    right: -60%;
  }

  .actions-menu {
    position: fixed;
    width: 60%;
    top: 120px;
    z-index: 100;
    transition: .1s all linear;

    .input-container {
      margin: 15px 0 15px 0;
    }

    .card-header {
      background-color: $black;
      border-bottom: 1px solid $dk-deloitte-green;
    }

    .panel-toggle {

      &:hover {
        cursor: pointer;
      }


      position: absolute;
      padding: 5px;
      width: 125px;
      top:0;
      left:-125px;
      border: 1px solid black;
      @extend .btn-primary
    }

    .actions {

    }
  }
}
