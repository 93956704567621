.login-page {

  background-color: $black;

  .login-card {
    border-color: $dk-deloitte-green;
    margin-top: 150px;
  }
}

