.summary-content {

  .summary-subheader {
    label {
      font-size: 12px;
      color: $dk-deloitte-gray;
    }
  }

  .card {
    height: 100%;

    .card-header {
      background: black;
      color: white;

      h6 {
        color: white;
      }

      .card-title {
        font-size: 20px;
      }
    }
  }

  .auto-resizer {
    div {height: 100% !important;}
  }

  .executive-summary {
    .dupes {
      p {margin: 0; font-size: 24px;}
      span {font-size: 32px;}
      .top-website {color: $primary; font-size: 32px; text-decoration: none;}
    }

    .product-container {

      border: 1px solid #D0D0CE;
      padding: 30px 10px;

      .product-image-container {
        width: 100%;
        height: 400px;
        border: 1px solid #D0D0CE;
      }
    }

    p {
      font-size: 16px;
      font-weight: 600;
      margin-top: 15px;
      margin-bottom: 0;
    }
  }

  .bar-property-type-split {
    background: red;

    &:hover {
      rect {cursor: pointer}
    }
  }

  .property-type-split {
    .pie-split-text {
      span {color: $primary;}
    }

    .radial-chart-property-type-split {
      &:hover {
        path {cursor: pointer}
      }
    }
  }

  .seller-significance {
    .high,
    .medium,
    .low {
      font-size: 24px;
      span {font-size: 32px; color: $primary}
    }
  }

  .significant-geographies {
    .map-legend {
      min-width: 100% !important;
    }
  }
}
