.dk-modal {
  .modal-header {
    background-color: $black;
    min-height: 30px;
  }

  .modal-title {
    font-size: 16px;
    font-weight: bold;
  }

  .modal-intro {
    margin-top: 27px;
    font-size: 14px;
  }

  label {
    font-size: 12px;
    color: $dk-dark-gray;
  }
}
